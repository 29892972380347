import styled from 'styled-components';
import { device } from 'theme';

const ImageSlider = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    height: 70vh;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
    display: none;
    }

    @media ${device.mdDown} {
        scroll-snap-type: x mandatory;
    }

`;

export default ImageSlider;