import styled from 'styled-components';
import React from 'react';
import { Container } from 'react-bootstrap';
import { device } from 'theme';

const PageWrapper = styled(props => <Container {...props} />)`
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;


    @media ${device.mdDown} {
        height: -webkit-fill-available;
    }

    @media ${device.xxlUp} {
          max-width: 80%;
      }

    `;

export default PageWrapper;