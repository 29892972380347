import styled from 'styled-components';
import { device } from 'theme';

const Contact = styled.div`
    font-size: 0.75rem;
    color: #9A9A9A;
    font-family: 'LibreBodoni';
    text-align: center;
    margin-top: 1em;
    letter-spacing: 0.1em;

    @media ${device.mdDown} {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

    > * a {
        text-decoration: none;
        color: inherit;
    }

    @font-face {
        font-family: 'LibreBodoni';
        src: url('./assets/fonts/LibreBodoni.ttf') format('truetype'), local('LibreBodoni');
        }
`;

export default Contact;