import Contact from 'components/Contact';
import Image from 'components/Image';
import ImageSlider from 'components/ImageSlider';
import Logo from 'components/Logo';
import PageWrapper from 'components/PageWrapper';
import React, { useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const MainPage = () => {
    const ref = useRef();

    const getArrayOfFileNames = r => {
        return r.keys();
    };
    const listOfImageNames = getArrayOfFileNames(require.context('../../public/assets/images', false, /\.(png|jpe?g)$/));


    useEffect(() => {
        ref.current.addEventListener("wheel", (evt) => {
            evt.preventDefault();
            ref.current.scrollLeft += evt.deltaY;
        });
    }, [])

    return (
        <PageWrapper>
            <Logo>
                Tim Lorenzo
            </Logo>
            <ImageSlider innerRef={ref}>
                {listOfImageNames.map(fileName =>
                    <Image key={uuidv4()} src={`./assets/images/${fileName}`} />
                )}
            </ImageSlider>
            <Contact>
                <div><a href='mailto:info@timlorenzo.com'>info@timlorenzo.com</a></div>
                <div><a href='https://www.instagram.com/timlorenzo' target="_blank">@timlorenzo</a></div>
            </Contact>
        </PageWrapper>
    )
};

export default MainPage;
