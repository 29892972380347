import styled from 'styled-components';
import { device } from 'theme';

const Logo = styled.h1`
    font-size: 2.5rem;
    font-family: 'Bodoni72Smallcaps';
    text-align: center;
    margin-bottom: 1em;

    @media ${device.mdDown} {
        margin-bottom: 0.5em;
        margin-top: 1em;
    }

    @media ${device.mdUp} {
        font-size: 2.5rem;
    }

    @font-face {
        font-family: 'Bodoni72Smallcaps';
        src: url('./assets/fonts/Bodoni_72_Smallcaps.ttf') format('truetype'), local('Bodoni72Smallcaps');
        }
`;

export default Logo;