import styled from 'styled-components';
import { device } from 'theme';

const Image = styled.img`
    src: ${props => props.src};
    height: 100%;
    width: auto;
    flex: 0 0 auto;
    margin-right: 2.5vw;

    @media ${device.mdDown} {
        scroll-snap-align: start;
    }
`;

export default Image;