const size = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  };
  
  const device = {
    xsUp: `(min-width: ${size.xs}px)`,
    smUp: `(min-width: ${size.sm}px)`,
    mdUp: `(min-width: ${size.md}px)`,
    lgUp: `(min-width: ${size.lg}px)`,
    xlUp: `(min-width: ${size.xl}px)`,
    xxlUp: `(min-width: ${size.xxl}px)`,
    smDown: `(max-width: ${size.sm - 1}px)`,
    mdDown: `(max-width: ${size.md - 1}px)`,
    lgDown: `(max-width: ${size.lg - 1}px)`,
    xlDown: `(max-width: ${size.xl - 1}px)`,
    xxlDown: `(max-width: ${size.xxl - 1}px)`,
  };
  
  export { device };