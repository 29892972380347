import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainPage from './containers/MainPage';
import { BrowserRouter as Router, Route } from 'react-router-dom';

const App = () => {

    return (
        <Router>
            <Route exact path="/" component={MainPage} />
        </Router>
    )
}

export default App;
